<template>
  <v-container fluid class="superTable">
    <v-row no-gutters class="marginrow">
      <v-col sm="4" class="mb-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('modules.quarters.list.table.searchPlaceholder')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :show-select="true"
          :headers="headers"
          :items="data"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table caption"
          style="table-layout: fixed"
          @click:row="goToDetails"
        >
          <template v-slot:top v-if="permissions.delete">
            <div
              :class="['superTable__bulkActions', 'py-1 px-1', areItemsSelected ? 'show' : 'hide']"
            >
              <v-divider></v-divider>
              <div class="py-3 px-2">
                <span
                  v-if="permissions.delete"
                  class="caption cursor-pointer hover-7-inverted"
                  @click="itemsToDelete"
                >
                  <v-icon size="22" class="mr-5">mdi-delete</v-icon>{{$t('modules.quarters.list.table.btnBulkDelete')}}
                </span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox caption"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox caption"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.name="{item}">
            <div class="superTable__table__item__name">{{ item.name }}</div>
          </template>

          <template v-slot:item.status="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:item.action="{item}" v-if="permissions.update || permissions.delete">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.update" @click="editItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>{{$t('modules.quarters.list.table.btnEdit')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="permissions.delete" @click="deleteItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>{{$t('modules.quarters.list.table.btnDelete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="mt-10">
      <v-col sm="3">
        <span
          class="subtitle-1 d-inline-block grey--text text--darken-1 mr-1"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-10 grey--text text--darken-1"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          v-model="page"
          :length="pageCount"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <span class="subtitle-1 grey--text text--darken-1 d-inline-block mr-2">{{$t('modules.quarters.list.table.goToPage')}}</span>
        <v-text-field
          :value="page"
          @input="($event) => { page = +$event }"
          max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4 grey--text text--darken-1"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.marginrow
  margin-left: 40px
.text-start, .sortable
  margin-left: 0px
</style>

<script>

export default {
  name: 'SuperTable',
  props: {
    data: Array,
    headers: Array,
    clearSelected: String,
    permissions: {update: false, delete: false},
  },
  data () {
    return {
      selectedItems: [],
      search: '',
      items: [
        { title: '1' },
        { title: '2' },
        { title: '3' },
      ],
      page: 1,
      itemsPerPage: 20,
      markedUsers: [],
      itemsPerPageAvailable: [20, 50, 100, 200]
    }
  },
  methods: {
    editItem (id) {
      this.$emit('ceo:update', id);
    },
    deleteItem (id) {
      this.$emit('ceo:delete', id);
    },
    goToDetails (item) {
      this.$emit('ceo:detail', item.id)
    },
    itemsToDelete() {
      this.$emit('bulk-delete', [...this.selectedItems]);
      this.selectedItems = [];
    }
  },
  watch: {
    page (val) {
      if (val > this.pageCount)
        this.page = +this.pageCount;
      if (val <= 1)
        this.page = 1;
    },
    clearSelected () {
      this.selectedItems = []
    }
  },
  computed: {
    // itemsPerPageAvailable() {
    //   const items = [20];
    //   let nextValue = 50;

    //   for (let i = 0; i < 3; i++) {
    //     nextValue = i ? nextValue * 2 : nextValue;

    //     if (this.data.length >= nextValue || this.data.length > (nextValue / 2) && this.data.length <= nextValue) {
    //       items.push(nextValue);
    //     }
    //   }

    //   return items;
    // },
    areItemsSelected () {
      return !!this.selectedItems.length
    },
    pageCount () {
      let counter = this.data.length / this.itemsPerPage;

      if (counter < 1) {
        counter = 1
      }

      return Math.ceil(counter)
    },
    itemsCount () {
      const counter = {
        totalItems: this.data.length,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.data.length) {
        counter.endItem = this.data.length
      }

      return counter
    }
  },
};
</script>
